import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "services",
        name: "services",
        component: () => import("../views/ServicesView.vue"),
      },
      {
        path: "contact-us",
        name: "contactus",
        component: () => import("../views/ContactUsView.vue"),
      },
      {
        path: "agent",
        name: "agent",
        component: () => import("../views/AgentView.vue"),
      },
      {
        path: "jobs",
        name: "jobs",
        component: () => import("@/views/JobsView.vue"),
      },
      {
        path: "faq",
        name: "faq",
        component: () => import("@/views/FAQView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
