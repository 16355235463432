<template>
  <div class="order-service">
    <b-container class="form-section">
      <b-row>
        <b-col sm="12" md="6">
          <div class="order-service__text">
            <h2>{{ $t("homePage.orderServiceHeader") }}</h2>
            <p>
              {{ $t("homePage.orderServiceSubHeader") }}
            </p>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="order-service__form">
            <b-form>
              <div class="pb-5">
                <b-form-select
                  v-model="selected"
                  :options="servicesOptions"
                ></b-form-select>
              </div>
              <div class="pb-5">
                <b-form-input
                  v-model="name"
                  :placeholder="$t('homePage.orderForm.name')"
                ></b-form-input>
              </div>
              <div class="pb-5">
                <b-form-input
                  type="number"
                  v-model="phone"
                  :placeholder="$t('homePage.orderForm.phone')"
                ></b-form-input>
              </div>
              <div class="pb-5">
                <b-form-input
                  type="email"
                  v-model="email"
                  :placeholder="$t('homePage.orderForm.email')"
                ></b-form-input>
              </div>
              <div class="pb-5">
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  class="custom-form-radio"
                >
                  <b-form-radio
                    v-model="orderType"
                    :aria-describedby="ariaDescribedby"
                    name="personal"
                    value="personal"
                    >{{ $t("homePage.orderForm.personal") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="orderType"
                    :aria-describedby="ariaDescribedby"
                    name="business"
                    value="business"
                    >{{ $t("homePage.orderForm.business") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="orderType"
                    :aria-describedby="ariaDescribedby"
                    name="goverment"
                    value="goverment"
                    >{{ $t("homePage.orderForm.government") }}</b-form-radio
                  >
                </b-form-group>
              </div>
              <div class="py5 message" v-if="message">
                {{ message }}
              </div>
              <div class="pb-5">
                <b-button class="form-btn" @click.prevent="onSubmit">{{
                  $t("homePage.orderForm.send")
                }}</b-button>
              </div>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <div class="section-header">
        <h2>{{ $t("homePage.internetSubsicription") }}</h2>
        <p>
          {{ $t("homePage.internetSubHeader") }}
        </p>
      </div>
      <div class="order-service__slider">
        <VueSlickCarousel v-bind="settings">
          <div>
            <div class="order-service__slider--card" dir="ltr">
              <div class="image">
                <div class="overlay"></div>
                <img src="../../assets/images/subs.png" alt="" />
              </div>
              <div class="content">
                <h3>ECO</h3>
                <ul>
                  <li>
                    <span>Pure</span>
                    <span>512k</span>
                  </li>
                  <li>
                    <span>Facebook</span>
                    <span>3Mb</span>
                  </li>
                  <li>
                    <span>Youtube</span>
                    <span>5Mb</span>
                  </li>
                  <li>
                    <span>TikTok</span>
                    <span>1Mb</span>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <span>512k</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div class="order-service__slider--card" dir="ltr">
              <div class="image">
                <div class="overlay"></div>
                <img src="../../assets/images/subs.png" alt="" />
              </div>
              <div class="content">
                <h3>ECO +</h3>
                <ul>
                  <li>
                    <span>Pure</span>
                    <span>1Mb - 1.5Mb</span>
                  </li>
                  <li>
                    <span>Facebook</span>
                    <span>4Mb</span>
                  </li>
                  <li>
                    <span>Youtube</span>
                    <span>7Mb</span>
                  </li>
                  <li>
                    <span>TikTok</span>
                    <span>2Mb</span>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <span>1Mb - 1.5Mb</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div class="order-service__slider--card" dir="ltr">
              <div class="image">
                <div class="overlay"></div>
                <img src="../../assets/images/subs.png" alt="" />
              </div>
              <div class="content">
                <h3>STANDARD</h3>
                <ul>
                  <li>
                    <span>Pure</span>
                    <span>1.5Mb - 2Mb</span>
                  </li>
                  <li>
                    <span>Facebook</span>
                    <span>6Mb</span>
                  </li>
                  <li>
                    <span>Youtube</span>
                    <span>8Mb</span>
                  </li>
                  <li>
                    <span>TikTok</span>
                    <span>3Mb</span>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <span>1.5Mb - 2Mb</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div class="order-service__slider--card" dir="ltr">
              <div class="image">
                <div class="overlay"></div>
                <img src="../../assets/images/subs.png" alt="" />
              </div>
              <div class="content">
                <h3>ACTIVE</h3>
                <ul>
                  <li>
                    <span>Pure</span>
                    <span>2Mb - 2.5Mb</span>
                  </li>
                  <li>
                    <span>Facebook</span>
                    <span>7Mb</span>
                  </li>
                  <li>
                    <span>Youtube</span>
                    <span>11Mb</span>
                  </li>
                  <li>
                    <span>TikTok</span>
                    <span>4Mb</span>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <span>2Mb - 2.5Mb</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div class="order-service__slider--card" dir="ltr">
              <div class="image">
                <div class="overlay"></div>
                <img src="../../assets/images/subs.png" alt="" />
              </div>
              <div class="content">
                <h3>BUSINESS</h3>
                <ul>
                  <li>
                    <span>Pure</span>
                    <span>2.5Mb - 3Mb</span>
                  </li>
                  <li>
                    <span>Facebook</span>
                    <span>8Mb</span>
                  </li>
                  <li>
                    <span>Youtube</span>
                    <span>13Mb</span>
                  </li>
                  <li>
                    <span>TikTok</span>
                    <span>5Mb</span>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <span>2.5Mb - 3Mb</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div class="order-service__slider--card" dir="ltr">
              <div class="image">
                <div class="overlay"></div>
                <img src="../../assets/images/subs.png" alt="" />
              </div>
              <div class="content">
                <h3>SPECIAL</h3>
                <ul>
                  <li>
                    <span>Pure</span>
                    <span>3Mb - 4Mb</span>
                  </li>
                  <li>
                    <span>Facebook</span>
                    <span>9Mb</span>
                  </li>
                  <li>
                    <span>Youtube</span>
                    <span>15Mb</span>
                  </li>
                  <li>
                    <span>TikTok</span>
                    <span>6Mb</span>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <span>3Mb - 4Mb</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div class="order-service__slider--card" dir="ltr">
              <div class="image">
                <div class="overlay"></div>
                <img src="../../assets/images/subs.png" alt="" />
              </div>
              <div class="content">
                <h3>SUPER</h3>
                <ul>
                  <li>
                    <span>Pure</span>
                    <span>4Mb - 5Mb</span>
                  </li>
                  <li>
                    <span>Facebook</span>
                    <span>11Mb</span>
                  </li>
                  <li>
                    <span>Youtube</span>
                    <span>17Mb</span>
                  </li>
                  <li>
                    <span>TikTok</span>
                    <span>7Mb</span>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <span>4Mb - 5Mb</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </b-container>
    <!-- <b-container>
      <div class="order-service__choose">
        <div class="choose-btn" @click.prevent="$bvModal.show('helpModal')">
          <div class="icon">
            <svg
              height="469.33333pt"
              viewBox="0 0 469.33333 469.33333"
              width="469.33333pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0"
              />
            </svg>
          </div>
          <div class="text">{{ $t("homePage.helpMeChoose") }}</div>
        </div>
        <div class="choose-btn">
          <div class="icon">
            <svg
              height="469.33333pt"
              viewBox="0 0 469.33333 469.33333"
              width="469.33333pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0"
              />
            </svg>
          </div>
          <div class="text">{{ $t("homePage.internetSpeedTest") }}</div>
        </div>
      </div>
    </b-container> -->

    <help-modal />
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// import Card from "@/components/sliderCards/orderServiceCard.vue";
import HelpModal from "@/components/home/helpModal.vue";
export default {
  name: "order-service",
  components: { VueSlickCarousel, HelpModal },
  data() {
    return {
      settings: {
        dots: true,
        arrow: true,
        infinite: true,
        initialSlide: 0,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerMode: true,
        autoplay: true,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              dots: true,
              arrows: false,
            },
          },
        ],
      },
      servicesOptions: [
        { text: this.$t("homePage.orderForm.serviceType"), value: "" },
        {
          text: this.$t("services.internet.title"),
          value: this.$t("services.internet.title"),
        },
        {
          text: this.$t("services.automation.title"),
          value: this.$t("services.automation.title"),
        },
        {
          text: this.$t("services.hosting.title"),
          value: this.$t("services.hosting.title"),
        },
        {
          text: this.$t("services.wireAndWireless.title"),
          value: this.$t("services.wireAndWireless.title"),
        },
        {
          text: this.$t("services.optical.title"),
          value: this.$t("services.optical.title"),
        },
        {
          text: this.$t("services.dataCenter.title"),
          value: this.$t("services.dataCenter.title"),
        },
        {
          text: this.$t("services.voip.title"),
          value: this.$t("services.voip.title"),
        },
        {
          text: this.$t("services.security.title"),
          value: this.$t("services.security.title"),
        },
        {
          text: this.$t("services.education.title"),
          value: this.$t("services.education.title"),
        },
        {
          text: this.$t("services.dataAnalysis.title"),
          value: this.$t("services.dataAnalysis.title"),
        },
      ],
      selected: "",
      orderType: "personal",
      name: "",
      phone: null,
      email: "",
      message: "",
    };
  },
  methods: {
    onSubmit() {
      if (this.selected === "") {
        this.message = "يرجى اختيار نوع الخدمة";
      } else if (this.name === "") {
        this.message = "يرجى كتابة الاسم الكامل";
      } else if (!this.phone) {
        this.message = "يرجى ادخال رقم الهاتف";
      } else if (this.email === "") {
        this.message = "يرجى ادخال البريد الالكتروني";
      } else {
        let body = `
        <p>service type: ${this.selected}</p>
        <br />
        <p>full name: ${this.name}</p>
        <br />
        <p>phone number: ${this.phone}</p>
        <br />
        <p>email address: ${this.email}</p>
        <br />
        <p>type: ${this.orderType}</p>
        `;

        let subject = "";
        let to = "";

        if (this.orderType === "personal") {
          subject = "Order Service - B2C";
          to = "saif.latif@dishtele.com";
        } else if (this.orderType === "business") {
          subject = "Order Service B2B";
          to = "m.saeed@dishtele.com";
        } else {
          subject = "Order Service B2G";
          to = "m.saeed@dishtele.com";
        }

        let data = new FormData();
        data.append("to", to);
        data.append("subject", subject);
        data.append("body", body);

        this.axios
          .post("http://192.168.224.177:9800", data)
          .then(() => {
            this.message = "تم ارسال طلبك بنجاح";
          })
          .catch(() => {
            this.message = "تم ارسال طلبك بنجاح";
          });
      }
    },
  },
};
</script>
