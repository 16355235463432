<template>
  <b-container class="p-0">
    <b-navbar
      toggleable="lg"
      class="main-nav"
      :class="{ 'nav-bg': isCollapsed }"
    >
      <b-navbar-toggle
        target="nav-collapse"
        @click.prevent="toggleCollapse"
      ></b-navbar-toggle>
      <div class="mobile-logo">
        <b-navbar-brand href="#">
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/images/dish-logo.png" alt="" />
          </router-link>
        </b-navbar-brand>
      </div>
      <b-collapse id="nav-collapse" is-nav>
        <div class="d-flex main-nav__item">
          <b-navbar-nav>
            <b-nav-item-dropdown :text="$i18n.locale" right>
              <b-dropdown-item href="#" @click.prevent="changeLang('ar')"
                >AR</b-dropdown-item
              >
              <b-dropdown-item href="#" @click.prevent="changeLang('en')"
                >EN</b-dropdown-item
              >
            </b-nav-item-dropdown>
            <b-nav-item href="#"
              ><router-link
                :to="{ name: 'home' }"
                active-class="active-link"
                exact
                >{{ $t("navbar.home") }}</router-link
              ></b-nav-item
            >
            <b-nav-item href="#">
              <!-- test -->
              <router-link
                :to="{ name: 'services' }"
                active-class="active-link"
                exact
                >{{ $t("navbar.services") }}
              </router-link>
            </b-nav-item>
          </b-navbar-nav>
        </div>

        <div
          class="d-flex justify-content-center main-nav__item main-nav__logo"
        >
          <b-navbar-brand href="#">
            <router-link :to="{ name: 'home' }">
              <img src="@/assets/images/dish-logo.png" alt="" />
            </router-link>
          </b-navbar-brand>
        </div>

        <div class="d-flex justify-content-end main-nav__item">
          <b-navbar-nav>
            <b-nav-item href="#"
              ><router-link
                :to="{ name: 'contactus' }"
                active-class="active-link"
                exact
                >{{ $t("navbar.contact") }}</router-link
              ></b-nav-item
            >
            <b-nav-item href="#"
              ><router-link
                :to="{ name: 'agent' }"
                active-class="active-link"
                exact
                >{{ $t("navbar.agent") }}</router-link
              ></b-nav-item
            >
          </b-navbar-nav>
        </div>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
export default {
  name: "navBar",
  props: ["isCollapsed"],
  // data() {
  //   return {
  //     isNotCollapsed: false,
  //   };
  // },
  methods: {
    toggleCollapse() {
      this.$emit("toggleCollapse");
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.$router.push({
        params: { lang: lang },
      });
      localStorage.setItem("lang", lang);
      document.documentElement.lang = lang;
      if (lang === "ar") {
        document.documentElement.dir = "rtl";
      } else {
        document.documentElement.dir = "ltr";
      }
    },
  },
};
</script>
