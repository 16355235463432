<template>
  <header class="main-header">
    <b-container class="h-100">
      <div class="h-100 d-flex align-items-center">
        <div class="main-header__content">
          <h1>{{ $t("homePage.mainTitle") }}</h1>
          <p>{{ $t("homePage.subTitle") }}</p>
          <div class="btn-container">
            <btn
              :text="$t('homePage.showMore')"
              activeBtn="active-btn"
              href="#services"
            />
          </div>
        </div>
      </div>
      <features />
    </b-container>
  </header>
</template>

<script>
import btn from "@/components/btn.vue";
import features from "@/components/home/featuresSection.vue";
export default {
  components: { btn, features },
};
</script>
