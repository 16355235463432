<template>
  <div class="home">
    <header-section />
    <services-section />
    <order-service />
    <!-- <clients-report /> -->
    <customers-section />
    <our-clients />
  </div>
</template>

<script>
import HeaderSection from "@/components/home/headerSection.vue";
import ServicesSection from "@/components/home/servicesSection.vue";
import OrderService from "@/components/home/orderService.vue";
// import ClientsReport from "@/components/home/clientsReport.vue";
import CustomersSection from "@/components/home/customersSection.vue";
import OurClients from "@/components/home/ourClients.vue";

export default {
  name: "HomeView",
  components: {
    HeaderSection,
    ServicesSection,
    OrderService,
    // ClientsReport,
    CustomersSection,
    OurClients,
  },
  methods: {},
};
</script>
