<template>
  <div class="features">
    <b-row>
      <b-col sm="12" md="4" class="p-0 mb-3">
        <div class="features__card">
          <div class="features__card--icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.49 51.55">
              >
              <g id="a" />
              <g id="b">
                <g id="c">
                  <g>
                    <path
                      class="d"
                      d="M23.1,33.05c-.53,0-1.03-.21-1.4-.58l-5.29-5.29c-.77-.77-.77-2.03,0-2.81,.77-.77,2.03-.77,2.81,0l3.89,3.89,9.17-9.17c.77-.77,2.03-.77,2.81,0,.77,.77,.77,2.03,0,2.81l-10.57,10.58c-.37,.37-.88,.58-1.4,.58Z"
                    />
                    <path
                      class="d"
                      d="M25.75,51.55c-.17,0-.34-.02-.5-.06C7.26,46.82-3.58,28.39,1.08,10.4c.09-.35,.19-.7,.29-1.04,.26-.88,1.14-1.45,1.99-1.42,7.76,.38,15.25-2.28,21.07-7.43,.75-.66,1.88-.66,2.63,0,5.82,5.15,13.3,7.83,21.07,7.44,.85-.03,1.73,.54,1.99,1.42,5.24,17.83-5,36.6-22.83,41.85-.35,.1-.69,.2-1.04,.29-.16,.04-.33,.06-.5,.06ZM4.79,11.93c-3.73,15.53,5.56,31.31,20.96,35.59,.14-.04,.28-.08,.43-.12,7.62-2.24,13.91-7.31,17.72-14.29,3.56-6.53,4.55-13.99,2.82-21.18-7.65,0-14.98-2.58-20.96-7.36-5.97,4.78-13.31,7.35-20.96,7.36Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="features__card--text">{{ $t("homePage.service1") }}</div>
          <!-- <div class="btn-container">
            <btn :text="$t('homePage.showMore')" activeBtn="" />
          </div> -->
        </div>
      </b-col>
      <b-col sm="12" md="4" class="p-0 mb-3">
        <div class="features__card">
          <div class="features__card--icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.55 51.55">
              <g id="a" />
              <g id="b">
                <g id="c">
                  <g>
                    <path
                      class="d"
                      d="M17.84,25.12c-6.92,0-12.56-5.63-12.56-12.56S10.92,0,17.84,0s12.56,5.63,12.56,12.56-5.63,12.56-12.56,12.56Zm0-21.15c-4.74,0-8.59,3.85-8.59,8.59s3.85,8.59,8.59,8.59,8.59-3.85,8.59-8.59S22.58,3.97,17.84,3.97Z"
                    />
                    <path
                      class="d"
                      d="M33.71,51.55c-1.1,0-1.98-.89-1.98-1.98v-5.29c0-4.74-3.85-8.59-8.59-8.59H12.56c-4.74,0-8.59,3.85-8.59,8.59v5.29c0,1.1-.89,1.98-1.98,1.98s-1.98-.89-1.98-1.98v-5.29c0-6.92,5.63-12.56,12.56-12.56h10.57c6.92,0,12.56,5.63,12.56,12.56v5.29c0,1.1-.89,1.98-1.98,1.98Z"
                    />
                    <path
                      class="d"
                      d="M36.35,24.8c-.88,0-1.69-.59-1.92-1.49-.27-1.06,.37-2.14,1.43-2.41,3.04-.78,5.42-3.15,6.19-6.19,1.18-4.59-1.6-9.28-6.19-10.46-1.06-.27-1.7-1.35-1.43-2.41,.27-1.06,1.36-1.7,2.41-1.43,3.25,.83,5.98,2.88,7.69,5.77,1.71,2.89,2.19,6.27,1.36,9.51-1.14,4.44-4.6,7.91-9.05,9.05-.17,.04-.33,.06-.49,.06Z"
                    />
                    <path
                      class="d"
                      d="M49.57,51.55c-1.1,0-1.98-.89-1.98-1.98v-5.29c-.02-3.88-2.67-7.28-6.44-8.26-1.06-.27-1.7-1.35-1.42-2.42s1.36-1.7,2.42-1.42c5.51,1.42,9.38,6.39,9.42,12.09v5.3c0,1.1-.89,1.98-1.98,1.98Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="features__card--text">{{ $t("homePage.service2") }}</div>
          <!-- <div class="btn-container">
            <btn :text="$t('homePage.showMore')" activeBtn="" />
          </div> -->
        </div>
      </b-col>
      <b-col sm="12" md="4" class="p-0 mb-3">
        <div class="features__card">
          <div class="features__card--icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.94 46.27">
              <g id="a" />
              <g id="b">
                <g id="c">
                  <g>
                    <path
                      class="d"
                      d="M22.47,46.27C11.17,46.27,1.98,37.07,1.98,25.78S11.17,5.29,22.47,5.29s20.49,9.19,20.49,20.49-9.19,20.49-20.49,20.49Zm0-37.01c-9.11,0-16.52,7.41-16.52,16.52s7.41,16.52,16.52,16.52,16.52-7.41,16.52-16.52S31.58,9.25,22.47,9.25Z"
                    />
                    <path
                      class="d"
                      d="M27.76,27.76h-5.29c-1.1,0-1.98-.89-1.98-1.98v-7.93c0-1.1,.89-1.98,1.98-1.98s1.98,.89,1.98,1.98v5.95h3.3c1.1,0,1.98,.89,1.98,1.98s-.89,1.98-1.98,1.98Z"
                    />
                    <path
                      class="d"
                      d="M1.98,9.25c-.61,0-1.22-.28-1.61-.82-.64-.89-.45-2.12,.44-2.77L8.09,.38c.88-.64,2.12-.45,2.77,.44,.64,.89,.45,2.12-.44,2.77L3.15,8.88c-.35,.26-.76,.38-1.16,.38Z"
                    />
                    <path
                      class="d"
                      d="M42.96,9.25c-.4,0-.81-.12-1.16-.38l-7.27-5.29c-.89-.64-1.08-1.88-.44-2.77,.64-.89,1.88-1.08,2.77-.44l7.27,5.29c.89,.64,1.08,1.88,.44,2.77-.39,.53-.99,.82-1.61,.82Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="features__card--text">{{ $t("homePage.service3") }}</div>
          <!-- <div class="btn-container">
            <btn :text="$t('homePage.showMore')" activeBtn="" />
          </div> -->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import btn from "@/components/btn.vue";
export default {
  name: "features-card",
  components: {
    // btn,
  },
};
</script>
