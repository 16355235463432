<template>
  <div id="app">
    <nav-bar :isCollapsed="isCollapsed" @toggleCollapse="handelCollapse" />
    <router-view />
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
export default {
  components: {
    navBar,
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  watch: {
    "$route.path": {
      handler: function () {
        this.isCollapsed = false;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handelCollapse() {
      return (this.isCollapsed = !this.isCollapsed);
    },
  },
};
</script>
