<template>
  <b-modal
    hide-footer
    size="lg"
    id="helpModal"
    :title="$t('homePage.helpPopup.title')"
  >
    <div v-if="!next">
      <b-form>
        <b-form-input
          v-model="users"
          type="number"
          :placeholder="$t('homePage.helpPopup.userNumber')"
        ></b-form-input>
        <b-form-select v-model="video" :options="videos"></b-form-select>
        <b-form-select v-model="game" :options="games"></b-form-select>
        <b-form-select v-model="platform" :options="platforms"></b-form-select>
      </b-form>
      <button @click.prevent="next = !next">
        {{ $t("homePage.helpPopup.send") }}
      </button>
    </div>
    <div class="card-section" v-else>
      <h3 class="card-section--header">
        {{ $t("homePage.helpPopup.yourSub") }}
      </h3>
      <div class="card-container">
        <div class="order-service__slider--card">
          <div class="image">
            <div class="overlay"></div>
            <img src="../../assets/images/subs.png" alt="" />
          </div>
          <div class="content">
            <h3>ECO</h3>
            <ul>
              <li>
                <span>pure</span>
                <span>512k</span>
              </li>
              <li>
                <span>facebook</span>
                <span>3k</span>
              </li>
              <li>
                <span>youtube</span>
                <span>5k</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button @click.prevent="next = !next">
        {{ $t("homePage.helpPopup.back") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      next: false,
      users: "",
      video: "",
      videos: [{ value: "", text: this.$t("homePage.helpPopup.video") }],
      game: "",
      games: [{ value: "", text: this.$t("homePage.helpPopup.game") }],
      platform: "",
      platforms: [{ value: "", text: this.$t("homePage.helpPopup.platform") }],
    };
  },
};
</script>
