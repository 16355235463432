<template>
  <div class="services" id="services">
    <b-container>
      <div class="section-header">
        <h2>{{ $t("homePage.serviceHeader") }}</h2>
        <p>{{ $t("homePage.serviceSectionSubHeader") }}</p>
      </div>
      <div class="services__slider">
        <VueSlickCarousel v-bind="settings">
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.internet.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-1.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.automation.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-2.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.hosting.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-3.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.wireAndWireless.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-4.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.optical.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-5.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.dataCenter.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-6.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.voip.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-7.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.security.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-8.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.education.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-9.png"
                alt="slider image"
              />
            </div>
          </div>
          <div>
            <div class="services__slider--card">
              <div class="services__slider--card-header">
                <h2>{{ $t("services.dataAnalysis.title") }}</h2>
                <router-link :to="{ path: 'services' }">
                  <div class="icon">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 443.52 443.52"
                      style="enable-background: new 0 0 443.52 443.52"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
			L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
			C342.889,227.058,342.889,216.255,336.226,209.591z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </router-link>
              </div>
              <div class="overlay"></div>
              <img
                src="@/assets/images/services/services-page-10.png"
                alt="slider image"
              />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </b-container>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// import Card from "@/components/sliderCards/servicesCard.vue";

export default {
  name: "ServicesSection",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        dots: true,
        arrow: true,
        infinite: true,
        initialSlide: 0,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerMode: true,
        autoplay: true,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              dots: true,
              arrows: false,
            },
          },
        ],
      },
    };
  },
};
</script>
