<template>
  <div class="our-clients">
    <div class="our-clients__logos">
      <b-container>
        <div class="section-header">
          <h2>{{ $t("homePage.customers.title") }}</h2>
          <p>{{ $t("homePage.clientsSubHeader") }}</p>
        </div>
      </b-container>
      <div class="clients-row">
        <img src="../../assets/images/client-1.png" alt="" />
        <img src="../../assets/images/client-2.png" alt="" />
        <img src="../../assets/images/client-3.png" alt="" />
        <img src="../../assets/images/client-4.png" alt="" />
        <img src="../../assets/images/client-5.png" alt="" />
      </div>
      <div class="clients-row">
        <img src="../../assets/images/client-6.png" alt="" />
        <img src="../../assets/images/client-7.png" alt="" />
        <img src="../../assets/images/client-8.png" alt="" />
        <img src="../../assets/images/client-9.png" alt="" />
        <img src="../../assets/images/client-10.png" alt="" />
        <img src="../../assets/images/client-11.png" alt="" />
      </div>
    </div>
    <footer-section />
  </div>
</template>

<script>
import FooterSection from "@/components/home/footerSection.vue";
export default {
  name: "our-clients",
  components: {
    FooterSection,
  },
};
</script>
